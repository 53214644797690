import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'

import Media from 'react-bootstrap/Media'
import Card from 'react-bootstrap/Card'

import Layout from '../components/layout'

export default class AboutUs extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const founderImgs = get(this, 'props.data.allFile.edges').reduce(
      (acc, { node }) => {
        const fileName = node.absolutePath.split`/founders/`[1]
        const fixed = node.childImageSharp.fixed
        acc[fileName] = fixed
        return acc
      },
      {}
    )
    const metaDescription = `Read the history of ${siteTitle} and get to know our superstar founders!`
    return (
      <Layout pageTitle="About Us" description={metaDescription}>
        <h1>About Us</h1>
        <p className="lead">
          {siteTitle} was founded in 2001 based on the teachings and research of
          educator Lynn Kuske. The company was incorporated in 2009.
        </p>

        <hr />

        <h2>Founders</h2>
        <Founder
          alt="Lynn Kuske"
          name="Lynn Kuske"
          imgFixed={founderImgs['lynn.jpg']}
        >
          Lynn began developing 4-group Math within the Bellevue School District
          in 2001. She has spent over 25 years in the business of growing young
          people, working as a parent educator and coach. She graduated from the
          University of Washington with a BA in The Family and Child Development
          and received her Master’s in Education from Antioch University,
          Seattle. Together, with her twin sister, Beth Campbell (below) she
          works to light the passion of math learning in every single young
          person she meets. Lynn is married to Brian and is a proud grandmother.
        </Founder>
        <Founder
          alt="Beth Campbell"
          name="Beth Campbell"
          imgFixed={founderImgs['beth.jpg']}
        >
          Beth has spent over 25 years teaching parents and children as an
          educator and coach. She developed Kuske Math, Inc.’s Multiplication
          Made Easy™ during years of volunteering at Enatai Elementary School,
          Bellevue, WA. She graduated Phi Beta Kappa from the University of
          Washington with a BA in Elementary Education while also playing
          basketball and golf for the Huskies. She has been the Varsity Girls’
          Basketball coach at Mercer Island and Bellevue Christian High Schools.
          Beth is married to Scott and is the mother of Chris (married to Kalla)
          and Steve.
        </Founder>
        <Founder
          alt="Melissa Lloyd, President"
          name="Melissa Lloyd, President"
          imgFixed={founderImgs['melissa.jpg']}
        >
          Melissa spent over 15 years building brands and businesses in consumer
          products and high tech industries. She worked on famous brands and
          brand turnarounds including: Topps Confectionery (Bazooka Bubble Gum,
          Ring Pop, Push Pop and Baby Bottle Pop Lollipops), Mattel Games, and
          James River Corporation (Dixie Cups). After she became a mother (now
          of three children), she became familiar with the challenges faced by
          children learning math and the breakthrough method in number sense
          introduced by Lynn Kuske. Melissa is also the co-founder of not for
          profit, Contagious Compassion “Growing Social Entrepreneurs”.
        </Founder>
      </Layout>
    )
  }
}

const Founder = ({ imgFixed, alt, name, children }) => (
  <Card>
    <Media>
      <Img fixed={imgFixed} className="m-3" alt={alt} />
      <Media.Body className="my-3 mr-3">
        <h3>{name}</h3>
        <p>{children}</p>
      </Media.Body>
    </Media>
  </Card>
)

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }

    allFile(filter: { relativeDirectory: { eq: "founders" } }) {
      edges {
        node {
          absolutePath
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
